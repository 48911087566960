// Sizes

.font-11 {
    font-size: rem(11px) !important;
}

.font-12 {
    font-size: rem(12px) !important;
}

.font-14 {
    font-size: rem(14px) !important;
}

.font-16 {
    font-size: rem(16px) !important;
}

.font-18 {
    font-size: rem(18px) !important;
}

.font-20 {
    font-size: rem(20px) !important;
}

.font-24 {
    font-size: rem(24px) !important;
}

.font-28 {
    font-size: rem(28px) !important;
}

.font-32 {
    font-size: rem(32px) !important;
}

.font-36 {
    font-size: rem(36px) !important;
}

.font-40 {
    font-size: rem(40px) !important;
}

.font-48 {
    font-size: rem(48px) !important;
}

.font-64 {
    font-size: rem(64px) !important;
}

// Line Heights

.lh-16 {
    line-height: rem(16px) !important;
}

.lh-20 {
    line-height: rem(20px) !important;
}

.lh-24 {
    line-height: rem(24px) !important;
}

.lh-32 {
    line-height: rem(32px) !important;
}

.lh-40 {
    line-height: rem(40px) !important;
}

// Colors

.brand-secondary {
    color: $secondary !important;
}

.green {
    color: $green !important;
}

.red {
    color: $red-d !important;
}

.confirm {
    color: $confirm !important;
}

.white {
    color: $white !important;
}

.gray-400 {
    color: $gray-400 !important;
}

.gray-600 {
    color: $gray-600 !important;
}

.gray-700 {
    color: $gray-700 !important;
}

.gray-800 {
    color: $gray-800 !important;
}

.gray-900 {
    color: $gray-900 !important;
}

.gray-5520 {
    color: $gray-5520;
}

// Weights

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

// Styles

.italic {
    font-style: italic;
}

// Headings

h1,
.h1,
h3 {
    font-weight: 700;
    margin: 0;
}

h1 {
    font-size: rem(32px);
}

.h1 {
    &-hero {
        font-size: rem(48px) !important;
    }
}

.h2 {
    &-sub {
        font-size: rem(20px);
        font-weight: 400;
    }
}

h3 {
    font-size: rem(40px);
    font-weight: 300;
}

h4 {
    &.sect {
        font-size: rem(32px);
    }
}

// Alignment

.text {
    &-c {
        text-align: center;
    }
    &-l {
        text-align: left;
    }
    &-r {
        text-align: right;
    }
}

// Lists

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        font-weight: 500;
        margin-bottom: rem(12px);
    }
}

.line-item {
    align-items: start;
    i {
        padding-top: rem(2px);
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $md) {
    // Headings
    h1 {
        font-size: rem(40px);
    }
}
