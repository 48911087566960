// Breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Color system

$primary: #1e124f !default;
$secondary: #4e34e5 !default;
$secondary-l: #eeecff !default;

$blue-dflt: #e1e6ee !default;
$green: #006c28 !default;
$green-l: #c6f0d5 !default;
$confirm: #115f13 !default;
$red: #ff621a !default;
$red-d: #b01717 !default;
$red-l: #ffd6c3 !default;
$gravatar: #0d253c !default;
$yellow: #ffac00 !default;

// Grayscale

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #4b4b4b !default;
$gray-800: #343a40 !default;
$gray-900: #323232 !default;
$gray-215215225: #D7D7E1 !default;
$gray-240240245: #F0F0F5 !default;
$gray-606065: #3c3c41 !default;
$gray-5520: #050514 !default;
$black: #000 !default;

// Sizes

$space-8: rem(8px);
$space-16: rem(16px);
$space-20: rem(20px);
$space-28: rem(28px);
$space-32: rem(32px);
$space-40: rem(40px);
$space-48: rem(48px);
$space-148: rem(148px);
$space-185: rem(185px);
$space-320: rem(320px);
