html {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

body {
    color: $gray-900;
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: rem(16px);
    font-weight: 400;
    .container {
        padding: rem(0 30px);
        position: relative;
    }
}

body,
#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

p {
    color: $gray-900;
    line-height: rem(32px);
}

.img {
    height: auto;
    max-width: 100%;
    &-centered {
        max-width: rem(300px);
        width: 100%;
    }
    &-128 {
        height: rem(128px);
        width: rem(128px);
    }
    &-72 {
        height: rem(72px);
        width: rem(72px);
    }
    &-300 {
        height: rem(300px);
    }
    &-user {
        border: solid 1px $gray-300;
        border-radius: $space-32;
        height: $space-32;
        overflow: hidden;
        width: $space-32;
    }
    &-user-md {
        border-radius: $space-40;
        height: $space-40;
        width: $space-40;
    }
    &-user-xl {
        border-radius: $space-148;
        height: $space-148;
        width: $space-148;
    }
    &-data-item {
        border: solid 1px $gray-300;
        border-radius: $space-32;
        height: $space-32;
        overflow: hidden;
        width: $space-32;
    }
    &-file {
        border: solid 1px $gray-300;
        border-radius: $space-32;
        height: $space-48;
        overflow: hidden;
        width: $space-48;
    }
    &-border {
        border: solid 1px $gray-300;
    }
    &-frame {
        border: solid rem(4px) white;
        box-shadow: 0px 0px 8px 4px $gray-500;
    }
    &-feat-img,
    &-feat-full {
        height: rem(320px);
    }
    &-corrective-feat {
        background-image: url('../img/corrective-sample-min.jpg');
        background-position: 80% 50%;
        background-repeat: no-repeat;
        background-size: 200%;
        transform: scaleX(-1);
    }
    &-preventative-feat {
        background-image: url('../img/preventative-sample-min.jpg');
        background-position: 75% 0%;
        background-repeat: no-repeat;
        background-size: 200%;
    }
    &-collab-feat {
        background-image: url('../img/collab-sample-min.jpg');
        background-position: 50% 30%;
        background-repeat: no-repeat;
        background-size: 200%;
    }
    &-preventative-full {
        background-image: url('../img/preventative-full-min.jpg');
        background-position: 20% 20%;
        background-repeat: no-repeat;
        background-size: 150%;
    }
    &-corrective-full {
        background-image: url('../img/corrective-full-min.jpg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 145%;
    }
    &-collab-full {
        background-image: url('../img/collab-full-min.jpg');
        background-position: 5% 50%;
        background-repeat: no-repeat;
        background-size: 150%;
    }
    &-team {
        height: $space-185;
        width: $space-185;
    }
    &-placeholder {
        left: 35%;
        position: absolute;
        top: 30%;
    }
}

// .sprite {
//     &-1 {
//         background-image: url('../img/sprite-min.png');
//         background-repeat: no-repeat;
//         background-size: 100%;
//         &-1 {
//             background-position: 0 0;
//         }
//         &-2 {
//             background-position: 0 rem(-128px);
//         }
//         &-3 {
//             background-position: 0 rem(-256px);
//         }
//         &-4 {
//             background-position: 0 rem(-384px);
//         }
//         &-5 {
//             background-position: 0 rem(-512px);
//         }
//         &-6 {
//             background-position: 0 rem(-640px);
//         }
//     }
//     &-2 {
//         background-image: url('../img/sprite-min.png');
//         background-repeat: no-repeat;
//         background-size: 100%;
//         &-1 {
//             background-position: 0 0;
//         }
//         &-2 {
//             background-position: 0 rem(-72px);
//         }
//         &-3 {
//             background-position: 0 rem(-144px);
//         }
//         &-4 {
//             background-position: 0 rem(-216px);
//         }
//         &-5 {
//             background-position: 0 rem(-288px);
//         }
//         &-6 {
//             background-position: 0 rem(-360px);
//         }
//     }
// }

.page {
    &-account,
    &-content {
        margin-left: $space-48;
        margin-top: $space-48;
        padding-top: $space-16;
    }
}

footer {
    background-color: $gray-200;
    a {
        color: $gray-700;
        font-size: rem(16px);
        font-weight: 500;
        &:hover {
            text-decoration: underline;
        }
    }
}
