.flex-or {
    &-c {
        display: flex !important;
        flex-direction: column !important;
    }
    &-r {
        display: flex !important;
        flex-direction: row !important;
    }
    &-1 {
        flex: 1 !important;
    }
    &-2 {
        flex: 2 !important;
    }
    &-3 {
        flex: 3 !important;
    }
    &-0 {
        flex: unset !important;
    }
    &-wrap {
        flex-wrap: wrap !important;
    }
    &-auto {
        flex: auto !important;
    }
}
