.flex {
    &-c {
        display: flex;
        flex-direction: column;
    }
    &-r {
        display: flex;
        flex-direction: row;
    }
    &-1 {
        flex: 1;
    }
    &-2 {
        flex: 2;
    }
    &-3 {
        flex: 3;
    }
    &-0 {
        flex: unset !important;
    }
    &-wrap {
        flex-wrap: wrap;
    }
    &-auto {
        flex: auto !important;
    }
}

.p-relative {
    position: relative;
}

.p-fixed {
    position: fixed;
}

.display {
    &-hide {
        display: none !important;
    }
}

.bckg {
    &-primary {
        background-color: $primary;
    }
    &-secondary {
        background-color: $secondary;
    }
    &-s-l {
        background-color: $secondary-l;
    }
    &-r-l {
        background-color: $red-l;
    }
    &-g-l {
        background-color: $green-l;
    }
    &-g100 {
        background-color: $gray-100;
    }
    &-g200 {
        background-color: $gray-200;
    }
    &-g300 {
        background-color: $gray-300;
    }
    &-g400 {
        background-color: $gray-400;
    }
    &-g500 {
        background-color: $gray-500;
    }
    &-g900 {
        background-color: $gray-900;
    }
    &-white {
        background-color: $white;
    }
    &-gravatar {
        background-color: $gravatar;
    }
    &-yellow {
        background-color: $yellow;
    }
    &-215215225 {
        background-color: $gray-215215225;
    }
    &-240240245 {
        background-color: $gray-240240245;
    }
    &-5520 {
        background-color: $gray-5520;
    }
}

.border {
    &-primary {
        border: solid 1px $primary !important;
    }
    &-secondary {
        border: solid 1px $secondary !important;
    }
    &-wh {
        border: solid 1px $white !important;
    }
    &-medium-wh {
        border: solid rem(4px) $white;
    }
    &-large-wh {
        border: solid rem(8px) $white;
    }
    &-bottom-g300 {
        border-bottom: solid 1px $gray-300 !important;
    }
    &-top-g300 {
        border-top: solid 1px $gray-300 !important;
    }
    &-g300 {
        border: solid 1px $gray-300 !important;
    }
    &-g700 {
        border: solid 1px $gray-700 !important;
    }
    &-error {
        border: solid 1px $red-d !important;
    }
    &-reset {
        border: 0px !important;
    }
    &-bottom-reset {
        border-bottom-width: 0px !important;
    }
    &-left-reset {
        border-left-width: 0px !important;
    }
    &-right-reset {
        border-right-width: 0px !important;
    }
}

.o-flow-y-hide {
    overflow-y: hidden;
    overscroll-behavior: contain;
}

.mb {
    &-30 {
        margin-bottom: rem(30px);
    }
}

.pt {
    &-1p {
        padding-top: 1px !important;
    }
}

.border-r {
    &-100 {
        border-radius: 100%;
    }
    &-16 {
        border-radius: rem(16px);
        &-bottom {
            border-bottom-left-radius: rem(16px);
            border-bottom-right-radius: rem(16px);
        }
        &-left {
            border-bottom-left-radius: rem(16px);
            border-top-left-radius: rem(16px);
        }
        &-right {
            border-bottom-right-radius: rem(16px);
            border-top-right-radius: rem(16px);
        }
        &-top {
            border-top-left-radius: rem(16px);
            border-top-right-radius: rem(16px);
        }
    }
    &-6 {
        border-radius: rem(6px);
        &-bottom {
            border-bottom-left-radius: rem(6px);
            border-bottom-right-radius: rem(6px);
        }
        &-top {
            border-top-left-radius: rem(6px);
            border-top-right-radius: rem(6px);
        }
    }
    &-reset {
        &-top {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}

.circle {
    &-sm {
        border-radius: 100%;
        height: $space-28;
        width: $space-28;
    }
    &-md {
        border-radius: $space-40;
        height: $space-40;
        width: $space-40;
    }
    &-xl {
        border-radius: $space-148;
        height: $space-148;
        width: $space-148;
    }
}
