a {
    color: $secondary;
    text-decoration: none;
}

.app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

nav {
    background-color: $white;
    display: flex;
    flex-direction: column;
    i {
        width: rem(16px);
    }
}

.menu-control {
    background-color: white;
    border-radius: rem(6px);
    max-height: 0;
    position: absolute;
    right: rem(16px);
    top: rem(64px);
    transition: max-height 0.15s ease-out;
    width: $space-185;
    z-index: 1001;
    &.open {
        border: solid 1px $gray-300;
        max-height: unset;
        transition: max-height 0.25s ease-in;
        .nav-item .nav-link {
            visibility: visible;
            opacity: 1;
            transition: visibility 2s ease-in, opacity 0.25s ease-in;
        }
        .menu-divider {
            display: flex;
            margin: rem(0 16px);
        }
        a.display-hide {
            display: flex !important;
        }
    }
    .nav-item {
        display: flex;
        align-items: flex-start;
        .nav-link {
            color: $gray-700;
            display: flex;
            margin: rem(4px 16px);
            opacity: 0;
            padding: rem(4px 0);
            transition: visibility 0.1s ease-out, opacity 0.15s ease-out;
            visibility: hidden;
            &:focus {
                color: $gray-900;
                text-decoration: underline;
            }
        }
        &:first-child {
            .nav-link {
                margin-top: rem(16px);
            }
        }
        &:last-child {
            .nav-link {
                padding-bottom: rem(16px);
                padding-top: rem(16px);
            }
        }
    }
    .menu-divider {
        display: none;
    }
}

.bar-control {
    background-color: white;
    border: solid 1px $gray-300;
    border-radius: rem(6px);
    display: none;
    max-height: 0;
    padding: rem(12px);
    position: absolute;
    right: rem(0px);
    top: rem(32px);
    transition: max-height 0.15s ease-out;
    width: $space-148;
    z-index: 1001;
    &.open {
        display: flex;
        flex-direction: column;
        max-height: unset;
        transition: max-height 0.25s ease-in;
    }
    .nav-item {
        color: $gray-700;
        display: flex;
        margin: rem(3px);
        padding: rem(3px);
        &:focus {
            color: $gray-900;
            text-decoration: underline;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.alert-bar {
    align-items: center;
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    height: $space-48;
    justify-content: center;
}

.side-bar {
    align-items: center;
    background-color: $primary;
    height: 100%;
    position: fixed;
    width: $space-48;
    .tab {
        align-items: center;
        color: $white;
        display: flex;
        flex-direction: column;
        font-size: rem(18px);
        height: $space-40;
        justify-content: center;
        width: $space-48;
    }
    .selected {
        background-color: $secondary;
        i {
            color: $white;
            transition: all 0.15s;
        }
    }
    .logo-sm {
        align-items: center;
        background-color: $secondary;
        display: flex;
        height: $space-48;
        justify-content: center;
        margin-bottom: rem(4px);
        width: $space-48;
        .logo {
            background-size: 80%;
            height: $space-48;
            width: $space-48;
        }
    }
}

.notify-bar {
    background-color: $white;
    margin-left: 0;
    opacity: 0;
    transition: width 0.25s linear 0s;
    width: 0;
    &.visible {
        border-left: solid 1px $gray-300;
        opacity: 100%;
        transition: width 0.25s linear 0s, opacity 0.25s linear 0.25s;
        width: $space-320;
    }
}

.nav-square {
    align-items: center;
    border-radius: rem(6px);
    display: flex;
    flex-direction: column;
    height: $space-32;
    justify-content: center;
    width: $space-32;
    i {
        color: transparentize($white, 0.2);
        font-size: rem(14px) !important;
        transition: all 0.15s;
    }
}

.task-bar {
    align-items: center;
    background-color: $primary;
    height: $space-48;
    margin-left: $space-48;
    padding: 0 rem(8px) 0 rem(24px);
    position: fixed;
    width: calc(100% - $space-48);
    z-index: 1;
}

.crumbs {
    background-color: $white;
    margin-left: $space-48;
    margin-top: $space-48;
    position: fixed;
    width: 100%;
    z-index: 1;
    .set-link {
        letter-spacing: 0.25px;
        margin: 0 $space-16;
        padding: $space-8;
        position: relative;
        text-shadow: 0.25px 0px 0.1px transparent, -0.25px 0px 0.1px transparent;
        &::after {
            background-color: transparent;
            border-radius: 2px;
            content: '';
            display: flex;
            flex-direction: column;
            height: 2px;
            position: relative;
            top: $space-16;
            width: 100%;
        }
        &.selected {
            text-shadow: 0.25px 0px 0.1px $gray-900, -0.25px 0px 0.1px $gray-900;
            &:hover {
                cursor: default;
                text-decoration: none;
            }
            &::after {
                background-color: $secondary;
            }
        }
    }
}

.logo-link {
    margin-right: rem(32px);
}
.logo {
    background-color: $secondary;
    background-image: url('../img/icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85%;
    border-radius: $space-8;
    height: $space-32;
    margin-right: $space-8;
    width: $space-32;
}

.btn {
    align-items: center;
    border: none;
    border-radius: rem(6px);
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: rem(16px);
    font-weight: 500;
    justify-content: center;
    padding: rem(16px 24px);
    transition: all 0.15s;
    &-primary {
        color: $primary;
        &:focus {
            background-color: transparentize($white, 0.3);
        }
        &-alt {
            color: $secondary;
            background-color: transparentize($secondary, 0.85);
            &:focus {
                background-color: transparentize($secondary, 0.6);
            }
        }
    }
    &-secondary {
        background-color: $secondary;
        color: $white;
        &:focus {
            background-color: darken($secondary, 15%);
        }
    }
    &-white {
        background-color: $white;
        border: solid 1px $secondary;
        color: $secondary;
        &:focus {
            background-color: darken($gray-100, 10%);
        }
    }
    &-dark {
        background-color: $gray-606065;
        color: $white;
        &:focus {
            background-color: darken($gray-606065, 5%);
        }
    }
    &-nav {
        border-radius: 0;
        justify-content: start;
        margin: rem(12px 16px);
        padding: rem(4px 0);
    }
    &-cancel {
        background-color: $white;
        border: solid 1px $red-d;
        color: $red-d;
        &:focus {
            background-color: $red-l;
        }
    }
    &-small {
        flex: none;
        font-size: rem(14px);
        max-height: rem(32px);
        padding: rem(20px 16px);
    }
    &-tile {
        flex: none;
        background-color: $gray-200;
        color: $gray-900;
        font-size: rem(18px);
        position: relative;
        &:focus {
            background-color: darken($gray-200, 15%);
        }
    }
    &-menu {
        border-radius: rem(6px);
        height: $space-32;
        transition: all 0.15s;
        width: $space-32;
        i {
            align-items: center;
            color: $gray-900;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 100%;
        }
    }
}

button {
    &.submit {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.link {
    color: $secondary;
    font-weight: 400;
}

.badge {
    align-items: center;
    background-color: $red;
    border-radius: $space-20;
    color: $white;
    display: flex;
    flex-direction: column;
    height: $space-20;
    justify-content: center;
    font-size: rem(12px);
    font-weight: 500;
    position: absolute;
    right: rem(-4px);
    top: rem(-4px);
    width: $space-20;
}

.touch {
    &-pop {
        background-color: $gray-300;
        border: none;
        border-radius: rem(16px);
        cursor: pointer;
        height: rem(32px);
        transition: background-color 0.1s ease-out;
        width: rem(32px);
        &:hover {
            background-color: darken($gray-300, 10%);
        }
    }
}

.blip {
    background-color: $gray-300;
    border-radius: rem(6px);
    padding: rem(16px);
    &-red {
        background-color: $red;
        color: $black;
    }
}

.divider {
    background-color: $gray-300;
    height: 1px;
    &-y {
        height: 100%;
        width: 1px;
    }
}

.icon {
    &::before {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
}

.bullet::before {
    content: '\f0da';
    color: $gray-600;
    font: var(--fa-font-solid);
    font-size: rem(14px);
    padding-right: rem(4px);
}

.list-group {
    margin-top: rem(16px);
    margin-left: rem(4px);
    &-item {
        line-height: rem(20px);
        margin-bottom: rem(16px);
    }
}

.interior-view {
    padding-bottom: rem(128px);
}

.form-control {
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;
    background-color: $white;
    background-clip: padding-box;
    border-radius: rem(6px);
    border: 1px solid $gray-300;
    color: $gray-900;
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: rem(16px);
    line-height: 1.5;
    min-height: rem(44px);
    padding: rem(8px 12px);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    &.disabled {
        background-color: $gray-200;
    }
}

.file-upload {
    &-first {
        border-top-left-radius: rem(6px);
        border-top-right-radius: rem(6px);
    }
    &-done-wrapper {
        border: 1px solid $gray-300;
        border-bottom: none;
        padding: rem(12px);
    }
    &-last {
        border-bottom: 1px solid $gray-300;
        border-bottom-left-radius: rem(6px);
        border-bottom-right-radius: rem(6px);
    }
}

.input-wrapper {
    margin-bottom: rem(24px);
}

label {
    font-weight: 500;
}

.input-desc {
    font-size: rem(12px);
    font-weight: 500;
    line-height: rem(16px);
    margin-top: rem(4px);
}

.progress-bar {
    background-color: $blue-dflt;
    border-radius: rem(2px);
    height: rem(4px);
}

.progress-bar-state {
    background-color: $secondary;
    border-radius: rem(2px);
    height: rem(4px);
    max-width: 100%;
}

.pill {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &-sm {
        border-radius: rem(24px);
        height: rem(24px);
        font-size: rem(14px);
        font-weight: 500;
        margin: (0px 8px 8px 0px);
        padding: rem(16px 12px);
        &-sq {
            padding: unset;
            width: rem(24px);
        }
    }
    &-xs {
        border-radius: rem(20px);
        height: rem(20px);
        font-size: rem(12px);
        font-weight: 500;
        padding: rem(12px 8px);
    }
}

.tag {
    background-color: $secondary;
    border-radius: rem(32px);
    color: $white;
    font-size: rem(10px);
    font-weight: 700;
    padding: rem(4px 8px);
    &.development {
        background-color: $white;
        border: solid 1px $secondary;
        color: $secondary;
    }
    &.archived {
        background-color: $gray-200;
        color: $gray-800;
    }
}

.tile {
    border: solid 1px $gray-300;
    border-radius: rem(6px);
    max-width: 100%;
    margin-bottom: rem(20px);
    padding: rem(16px);
    width: 100%;
    &-trial {
        background-color: $secondary;
        border: none;
        color: $white;
    }
    &-full {
        margin: 0;
        width: 100% !important;
    }
    &-feat {
        align-items: center;
        border: none;
        text-align: center;
    }
    &-strip {
        border: none;
    }
    &-padded {
        padding: rem(32px);
    }
}

.widget {
    border: solid 1px $gray-300;
    border-radius: rem(6px);
    margin-bottom: rem(20px);
    padding: rem(16px);
    width: 100%;
}

.basic-wrapper {
    border: solid 1px $gray-300;
    border-radius: rem(6px);
    padding: rem(16px);
}

.data {
    &-list {
        align-items: center;
        background-color: $white;
        padding: rem(16px 20px);
        .dl-item {
            flex: 1;
        }
    }
}

.toggle-sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}
.toggle-wrapper {
    border-radius: rem(8px);
    margin: rem(12px);
    padding: 2px;
    position: relative;
    a {
        color: $gray-900;
        font-size: rem(14px);
        height: rem(28px);
        padding: unset;
        width: rem(128px);
        z-index: 1001;
    }
    .toggle-slide {
        background-color: $white;
        border-radius: rem(6px);
        height: rem(28px);
        position: absolute;
        top: 2px;
        width: rem(128px);
        z-index: 0;
        &.left {
            transform: translateX(0);
            transition: all 0.2s ease-in-out;
        }
        &.right {
            transform: translateX(rem(128px));
            transition: all 0.2s ease-in-out;
        }
    }
}

.shadow {
    box-shadow: 0px 0px 8px -2px rgb(12 13 20 / 30%);
}

.frame {
    border-radius: rem(6px);
    padding: rem(24px);
}

.modal-background {
    align-items: center;
    background-color: transparentize($black, 0.1);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1003;
}

.modal-content {
    background-color: $white;
    border-radius: rem(6px);
    display: flex;
    flex-direction: column;
    padding: rem(28px);
}

.modal-header {
    display: flex;
    flex-direction: row;
    i {
        cursor: pointer;
    }
}

.modal-title {
    flex: 1;
    font-size: rem(18px);
    font-weight: 500;
    margin: 0;
}

.cookie-robot {
    align-items: baseline;
    background-color: transparentize($gray-900, 0.1);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: rem(0 16px);
    position: fixed;
    width: 100%;
    z-index: 1002;
}
