// Small devices (landscape phones, 576px and up)
@media (min-width: $sm) {
    .display {
        &-sm {
            &-flex {
                display: flex !important;
            }
            &-hide {
                display: none !important;
            }
        }
    }
    .flex-sm {
        &-c {
            display: flex;
            flex-direction: column;
        }
        &-r {
            display: flex;
            flex-direction: row;
        }
        &-1 {
            flex: 1;
        }
        &-2 {
            flex: 2;
        }
        &-3 {
            flex: 3;
        }
        &-0 {
            flex: unset !important;
        }
    }

    .tile {
        margin-bottom: 2.5%;
        margin-right: 2.5%;
        width: 47.5%;
        &:nth-child(even) {
            margin-right: 0;
        }
        &-feat {
            margin-bottom: 5%;
        }
    }

    .img {
        &-300 {
            height: rem(320px);
        }
    }

    .pill {
        &-sm {
            &-sq {
                padding: rem(16px 12px);
                width: unset;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: $md) {
    .display {
        &-md {
            &-flex {
                display: flex !important;
            }
            &-hide {
                display: none !important;
            }
        }
    }
    .flex-md {
        &-c {
            display: flex;
            flex-direction: column;
        }
        &-r {
            display: flex;
            flex-direction: row;
        }
        &-1 {
            flex: 1;
        }
        &-2 {
            flex: 2;
        }
        &-3 {
            flex: 3;
        }
        &-0 {
            flex: unset !important;
        }
    }

    .widget {
        transition: background-color 0.15s;
        width: calc(50% - rem(12px));
        &:nth-child(odd) {
            margin-right: rem(20px);
        }
        &:focus {
            background-color: $gray-200;
            transition: background-color 0.15s;
        }
    }

    .lh-md-xxxl {
        line-height: rem(40px) !important;
    }

    .font-20-xxl {
        font-size: rem(32px) !important;
    }

    h4 {
        &.sect {
            font-size: rem(48px);
        }
    }

    .img {
        &-collab-full {
            background-position: 0% 50%;
            background-size: 130%;
        }
        &-preventative-full {
            background-position: 0% 25%;
            background-size: 110%;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: $lg) {
    .display {
        &-lg {
            &-flex {
                display: flex !important;
            }
            &-hide {
                display: none !important;
            }
        }
    }
    .flex-lg {
        &-c {
            display: flex !important;
            flex-direction: column !important;
        }
        &-r {
            display: flex !important;
            flex-direction: row !important;
        }
        &-1 {
            flex: 1;
        }
        &-2 {
            flex: 2;
        }
        &-3 {
            flex: 3;
        }
        &-0 {
            flex: unset !important;
        }
    }

    .display {
        &-lg {
            &-show {
                display: flex !important;
            }
            &-hide {
                display: none !important;
            }
        }
    }

    .menu-control {
        background-color: transparent;
        border-radius: unset;
        max-height: unset;
        padding: 0;
        position: relative;
        right: unset;
        top: unset;
        width: unset;
        .nav-item {
            .nav-link {
                color: $gray-900;
                padding-bottom: 0;
                opacity: 1;
                visibility: visible;
                font-weight: 500;
                margin: rem(0 16px);
                &:focus {
                    color: $gray-900;
                    text-decoration: none;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &.active {
                .nav-link {
                    text-decoration: underline;
                }
            }
            &:first-child {
                .nav-link {
                    margin-top: 0;
                }
            }
            &:last-child {
                .nav-link {
                    padding-bottom: 0;
                    padding-top: 0;
                }
            }
        }
    }

    .logo-link:hover {
        text-decoration: none;
    }

    .nav-square {
        &:hover {
            background-color: transparentize($white, 0.8);
            transition: all 0.15s;
            i {
                color: $white;
            }
        }
        &.selected:hover {
            background-color: $secondary;
            transition: all 0.15s;
        }
    }

    .btn {
        &:hover {
            text-decoration: underline;
            transition: all 0.15s;
        }
        &:focus {
            transition: all 0.15s;
        }
        &-nav {
            margin: 0;
            padding: unset;
            &:hover {
                border-bottom: solid rem(2px) $white;
                text-decoration: none;
            }
            &.active {
                border-bottom: solid rem(2px) $white;
                border-left: none;
                padding-left: 0;
            }
        }
        &-white {
            &-w {
                &:focus {
                    background-color: transparentize($primary, 0.9);
                }
            }
            &-p {
                &:focus {
                    background-color: transparentize($white, 0.1);
                }
            }
        }
        &-tile {
            &:hover {
                background-color: darken($gray-200, 5%);
                text-decoration: none;
            }
        }
        &-menu:hover {
            background-color: $gray-200;
            cursor: pointer;
            text-decoration: none;
        }
    }

    button:hover,
    .hover:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .hover-itm {
        transition: all 0.15s;
        &:hover {
            box-shadow: 0px 0px 4px -2px $gray-800;
            cursor: pointer;
            transition: all 0.15s;
        }
    }

    .data {
        &-list {
            .dl-item {
                min-width: 14%;
            }
            &:hover {
                cursor: pointer;
                .dl-item-title {
                    text-decoration: underline;
                }
            }
        }
    }

    .text {
        &-lg-c {
            text-align: center;
        }
        &-lg-l {
            text-align: left;
        }
    }

    .img {
        &-feat-full {
            height: 100%;
        }
        &-corrective-full {
            background-position: 50% 0%;
            background-size: 300%;
        }
        &-preventative-full {
            background-position: 33% 0%;
            background-size: 300%;
        }
        &-collab-full {
            background-position: 25% 0%;
            background-size: 300%;
        }
    }

    .border-lg-r {
        &-16 {
            border-radius: rem(16px);
            &-bottom {
                border-bottom-left-radius: rem(16px);
                border-bottom-right-radius: rem(16px);
            }
            &-left {
                border-bottom-left-radius: rem(16px);
                border-top-left-radius: rem(16px);
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
            &-right {
                border-bottom-right-radius: rem(16px);
                border-top-right-radius: rem(16px);
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
            &-top {
                border-top-left-radius: rem(16px);
                border-top-right-radius: rem(16px);
            }
        }
    }

    .border {
        &-bottom-lg-g300 {
            border-bottom: solid 1px $gray-300 !important;
        }
        &-bottom-lg-reset {
            border-bottom-width: 0px !important;
        }
        &-left-lg-reset {
            border-left-width: 0px !important;
        }
        &-right-lg-reset {
            border-right-width: 0px !important;
        }
    }

    .cookie-robot {
        align-items: center;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $xl) {
    .tile {
        &-sale {
            margin-bottom: 2%;
            margin-right: 2%;
            width: 18%;
            &:nth-child(even) {
                margin-right: 2%;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &-feat {
            margin-bottom: 2.5%;
            margin-right: 2.5%;
            width: calc(33.333% - 1.75%);
            &:nth-child(even) {
                margin-right: 2.5% !important;
            }
            &:nth-child(3) {
                margin-right: 0;
            }
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    .widget {
        margin-bottom: rem(20px);
        width: calc(33.333% - rem(14px));
        &:nth-child(odd),
        &:nth-child(2n) {
            margin-right: rem(20px);
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    .img {
        &-feat-full {
            height: 100%;
        }
        &-corrective-full {
            background-position: 50% 20%;
            background-size: 200%;
        }
        &-preventative-full {
            background-position: 45% 20%;
            background-size: 215%;
        }
        &-collab-full {
            background-position: 23% 0%;
            background-size: 250%;
        }
    }
}
